import React from "react"
import HeroSlides from "../components/hero-slides"
import Layout from "../components/layout"
import HomeStats from "../components/home-stats"
import HomeSolutions from "../components/home-solutions"
import HomeAbout from "../components/home-about"
import Footer from "../components/footer"
import HomeVideo from "../components/home-video"
import HomeWorkingProcess from "../components/home-working-process"
import HomeRecentProjects from "../components/home-recent-projects"
import HomeClientTestimonials from "../components/home-client-testimonials"
import Technologies from "../components/technologies"
import Testimonials from "../components/home-client"
import Hometech from "../components/home-tech"

import SocialIconsFooter from "../components/social-icons-footer"
import HomeCdn from "../components/home-cdn"

const IndexPage = () => {
    return (
        <Layout
            pageTitle="PragICTS | Software Development Services Company In Sri Lanka"
        >
            <div id="wrapper">
                <div className="scroll-nav-wrap fl-wrap">
                    <div className="scroll-down-wrap">
                        <div className="mousey">
                            <div className="scroller"></div>
                        </div>
                        <span>Scroll Down</span>
                    </div>
                   
                </div>

                <HeroSlides />

              
                <Footer />
            </div>
        </Layout>
    )
}

export default IndexPage
