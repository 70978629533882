import React from 'react'
import HeroImage1 from '../images/hero-images/banner-software-development-ezgif.com-avif-to-jpg-converter.jpg'
import HeroImage2 from '../images/hero-images/banner-website-development.jpeg.jpg'
import HeroImage3 from '../images/hero-images/banner-ecommerce-development.jpg'
import HeroImage4 from '../images/hero-images/banner-web-application-development.jpg'
import HeroImage5 from '../images/hero-images/banner-mobile-application-development1-ezgif.com-avif-to-jpg-converter.jpg'
import HeroImage6 from '../images/hero-images/banner-software-consulting.jpg'

const slides = [
    {
        id: 1,
        image: HeroImage1,
        mainText: '#1 Software  <br /> <span>Development Services</span>',
        subText: 'Build the software you want (UI/UX, Productive, Secure, Scalable, Relevent, and Effective).',
       
    },
    {
        id: 2,
        image: HeroImage2,
        mainText: '#1 Website  <br /> <span>Development Services </span>', 
        subText: 'Website and online presence for positioning, visibility, reach, and growth.'
    },
    {
        id: 3,
        image: HeroImage3,
        mainText: '#1 eCommerce<br />  <span>   Development Services</span>',
        subText: 'Develop e-commerce as a key channel for online customer convenience, sales, and reach.',
    },
  
    {
        id: 4,
        image: HeroImage4,
        mainText: '#1 Web Browser-Based Application <br /> <span>Development Services</span>',
        subText: 'Build and integrate software to streamline operations (B2B, B2C, B2E, etc.) for competitive edge and throughput'
    },
    {
        id: 5,
        image: HeroImage5,
        mainText: '#1 Mobile Application <br /> <span>Development Services</span>',
        subText: 'Make available business functions to edge devices for seamless operations and conveniences'
    },
    {
        id: 6,
        image: HeroImage6,
        mainText: '#1 Software<br /> <span>Consulting Services</span>',
        subText: 'Software consulting including refactoring, rationalization, and optimization for cost and performance optimizations.'
    }
    
]

const HeroSlides = () => {

    return (
        <div className="hero-wrap" id="sec1" data-scrollax-parent="true">
            <div className="slider-carousel-wrap full-height fullscreen-slider-wrap">
                <div className="fullscreen-slider full-height cur_carousel-slider-container fl-wrap" data-slick='{"autoplay": true, "autoplaySpeed": 4000 , "pauseOnHover": false}'>
                {slides.map(slide => (
                    <div key={slide.image} className="fullscreen-slider-item full-height fl-wrap">
                        <div className="bg par-elem hero-bg"  data-bg={slide.image}></div>
                        <div className="overlay"></div>
                        <div className="half-hero-wrap">
                            <h1 dangerouslySetInnerHTML={{ __html: slide.mainText }}></h1>
                            <h4>{slide.subText}</h4>
                            <div className="clearfix"></div>
                            {/* <a href={slide.action.link} className="custom-scroll-link btn float-btn flat-btn color-btn mar-top">Let's Start</a> */}
                        </div>
                    </div>
                ))}
                </div>
                <div className="sp-cont   sp-cont-prev"><i className="fal fa-arrow-left"></i></div>
                <div className="sp-cont   sp-cont-next"><i className="fal fa-arrow-right"></i></div>
                <div className="fullscreenslider-counter"></div>
            </div>
            <div className="hero-decor-numb">
                <span>6.9149106  </span><span>79.9105932 </span>
                <a href="https://g.page/PragICTS?share" target="_blank" className="hero-decor-numb-tooltip">Based In Colombo, Sri Lanka</a>
            </div>
        </div>
    )
}

export default HeroSlides
